import classNames from 'classnames';
import CtaLink from 'components/CtaLink';
import ReviewBadge from 'components/reviewBadge';
import { useScrollToSlice } from 'hooks/useScrollToSlice';
import Image from 'next/image';
import { BlockComponentProps } from 'strapi/types/utils';
import { getResponsiveImgHeight } from 'util/getResponsiveImgSize';

import styles from './HeroBanner.module.scss';

export const HeroBanner = ({
  block,
  onError,
}: BlockComponentProps<'marketing-website.hero-banner'>) => {
  const {
    title,
    description,
    image,
    primary_cta_text: primaryCtaText,
    primary_cta_link: primaryCtaLink,
    secondary_cta_link: secondaryCtaLink,
    secondary_cta_text: secondaryCtaText,
    has_background_color: hasBackgroundColor,
    cta_link_id: ctaLinkId,
  } = block;

  const ctaStyles = `w100 p-p fw-bold ta-center c-pointer br8 ${styles.cta}`;
  const primaryCtaStyles = `${ctaStyles} ${
    hasBackgroundColor ? styles.greyPrimaryCta : 'p-btn--primary'
  }`;
  const secondaryCtaStyles = `${ctaStyles} ${
    hasBackgroundColor
      ? // Ideally we shouldn't use "primary" class to style secondary. This is a temporary solution as an update to a neutral button names are coming soon.
        `p-btn--primary ${styles.greySecondaryCta}`
      : `p-btn--secondary ${styles.purpleSecondaryCta}`
  }`;

  const sectionId = 'hero-banner-block';

  useScrollToSlice(sectionId, 72);

  if (!image?.data) return onError('Missing image');
  if (!image.data.attributes.alternativeText)
    return onError('Missing alt text of the hero banner');
  const { width, height } = image.data.attributes;
  if (!width || !height)
    return onError(
      'Dimensions were missing on the image payload. Try replace with .png if image file is .avif.'
    );

  /* We calculate the image height based on the max width we know the image will have on the page, and pass the dimensions to NextImage:
  https://nextjs.org/docs/pages/building-your-application/optimizing/images#image-sizing */
  const RESIZED_IMAGE_WIDTH = 400;
  const responsiveHeight = getResponsiveImgHeight(RESIZED_IMAGE_WIDTH, {
    width,
    height,
  });

  return (
    <section
      id={sectionId}
      className="d-flex jc-between ai-center gap40 fd-column"
    >
      <div
        className={classNames(
          'd-flex jc-between ai-center gap40 p24 br8 mx16',
          styles.container,
          {
            [styles.containerWithBackground]: hasBackgroundColor,
            [styles.containerWithoutBackground]: !hasBackgroundColor,
          }
        )}
      >
        <div>
          <h1 className="p-h1--xl p--serif">{title}</h1>
          <p className="p-p fw-bold mt16">{description}</p>
          <div className={`my24 d-flex gap16 ${styles.buttonContainer}`}>
            {primaryCtaLink && primaryCtaText && (
              <CtaLink
                href={primaryCtaLink.url}
                className={primaryCtaStyles}
                target={primaryCtaLink.target}
                ctaLinkId={ctaLinkId}
              >
                {primaryCtaText}
              </CtaLink>
            )}
            {secondaryCtaLink && secondaryCtaText && (
              <CtaLink
                href={secondaryCtaLink.url}
                className={secondaryCtaStyles}
                target={secondaryCtaLink.target}
              >
                {secondaryCtaText}
              </CtaLink>
            )}
          </div>
        </div>
        <div className={styles.image}>
          <Image
            src={image.data.attributes.url}
            width={RESIZED_IMAGE_WIDTH}
            height={responsiveHeight}
            alt={image.data.attributes.alternativeText}
          />
        </div>
      </div>
      <ReviewBadge />
    </section>
  );
};
